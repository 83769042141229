import { useEffect, useState } from "react";
import moment from "moment-timezone";
import MainContainer from "../../layout/MainContainer";
import {
  Card,
  Table,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Box,
  TableBody,
  Button,
  Modal,
  TextField,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
} from "@mui/material";
import Grid from "@mui/material/Grid";
import type { couponcodedata } from "../../types/General";
import { Loader, showError } from "../../constants";
import { Pagination, SearchBar } from "../../components";
import { isValidInput } from "../../utils/validations";
import { useNavigate } from "react-router-dom";
import {
  Createcouponcode,
  Editcouponcode,
  Getcouponcode,
} from "../../services/coupon";
import { toast } from "react-toastify";

const Couponcode = () => {
  const navigate = useNavigate();

  const [allcouponcode, setAllcouponcode] = useState<any[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [totalCount, setTotalCount] = useState<number>(0);
  const [debouncedSearchTerm, setDebouncedSearchTerm] = useState<string>("");
  const [searchTerm, setSearchTerm] = useState<string>("");
  const [page, setPage] = useState(1);
  const [isEditMode, setIsEditMode] = useState<boolean>(false);
  const [selectedCoupon, setSelectedCoupon] = useState<any>(null);

  let totalPages = Math.ceil(totalCount / 10);

  const onPageChange = (newPage: number) => {
    setPage(newPage);
  };

  const getCurrentDateTime = () => {
    const now = new Date();
    const year = now.getFullYear();
  const month = String(now.getMonth() + 1).padStart(2, "0"); // Month is 0-indexed
  const date = String(now.getDate()).padStart(2, "0");
  const hours = String(now.getHours()).padStart(2, "0");
  const minutes = String(now.getMinutes()).padStart(2, "0");

  return `${year}-${month}-${date}T${hours}:${minutes}`;
  };

  const [open, setOpen] = useState(false);
  const [formData, setFormData] = useState({
    coupon_code: "",
    amount: "", // Initialize amount as a string
    expiry_date: "",
    product_id: "",
    _id: ""
  });
  const [errors, setErrors] = useState({
    coupon_code: "",
    amount: "",
    expiry_date: "",
    product_id: "",
  });

  const handleOpen = (coupon?: any) => {
    if (coupon) {
      setIsEditMode(true);
      setSelectedCoupon(coupon);
      setFormData({
        coupon_code: coupon.coupon_code,
        amount: coupon.amount,
        expiry_date: coupon.expiry_date,
        product_id: coupon.product_id,
        _id: coupon._id
      });
    } else {
      setIsEditMode(false);
      setFormData({
        coupon_code: "",
        amount: "",
        expiry_date: "",
        product_id: "",
        _id: ""
      });
    }
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setFormData({
      coupon_code: "",
      amount: "",
      expiry_date: "",
      product_id: "",
      _id: ""
    });
    setIsEditMode(false);
    setSelectedCoupon(null);
  };
  
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    
    if(name == "expiry_date"){
      if (new Date(value) < new Date(getCurrentDateTime())) {
        setErrors({
          ...errors,
          [name]: "You cannot select a past date or time!",
        });
        setFormData({
          ...formData,
          [name]: "",
        });

        return;
      }
    }
    setFormData({
      ...formData,
      [name]: name === "amount" && value === "" ? "" : value,
    });
    setErrors({
      ...errors,
      [name]: "",
    });
  };

  const handleCreate = async () => {
    const newErrors = {
      coupon_code: formData.coupon_code ? "" : "Coupon code is required",
      amount: formData.amount ? "" : "Amount is required",
      expiry_date: formData.expiry_date ? "" : "Expiry date is required",
      product_id: formData.product_id ? "" : "Product ID is required",
    };

    setErrors(newErrors);
    const isValid = Object.values(newErrors).every((error) => error === "");
    if (isValid) {
      const couponData: {
        coupon_code: string;
        amount: number;
        expiry_date?: string;
        product_id?: string;
        _id?: string
      } = {
        coupon_code: formData.coupon_code.trim(),
        amount: formData.amount ? Number(formData.amount) : 0,
        expiry_date: formData.expiry_date,
        product_id: formData.product_id,
        _id: formData._id
      };
      try {
        let response;
        if (isEditMode && selectedCoupon) {
          response = (await Editcouponcode(couponData)) as {
            code: number;
            data: any;
            message: string;
          };
        } else {
          delete couponData._id
          response = (await Createcouponcode(couponData)) as {
            code: number;
            data: any;
            message: string;
          };
        }
        if (response.code === 200) {
          handleClose();
          getcouponcode();
          toast.success(response?.message || "");
        }
      } catch (error: any) { }
    }
  };

  const getcouponcode = async () => {
    try {
      setLoading(true);
      const response = (await Getcouponcode({
        limit: 10,
        page: page,
        search: searchTerm,
      })) as {
        code: number;
        data: {
          totalitems: number;
          list: couponcodedata[];
        };
      };
      if (response.code === 200) {
        setLoading(false);
        setAllcouponcode(response?.data?.list || []);
        setTotalCount(response?.data?.totalitems || 10);
        setLoading(false);
      } else {
        setLoading(false);
        setAllcouponcode([]);
      }
      setLoading(false);
    } catch (error: any) {
      setLoading(false);
      showError(error?.data?.message || "");
    }
  };

  useEffect(() => {
    getcouponcode();
  }, [debouncedSearchTerm, page]);



  const handlechangestatus = async (item: any, status: string) => {
    try {
      const response = (await Editcouponcode({
        coupon_code: item?.coupon_code,
        status: status,
        _id: item._id
      })) as {
        code: number;
        data: any;
        message: string;
      };
      if (response.code === 200) {
        getcouponcode();
        toast.success(response?.message || "");
      } else {
        toast.error(response?.message || "");
      }
    } catch (error: any) {
      showError(error?.data?.message || "");
    }
  };

  const handleChangeAnnualPlan = (event: any) => {
    const { value } = event.target; // This is now a string (e.g., 'anual_insider_subscription_199')
    const options = [
      { value: 'anual_insider_subscription_299', price: '299' },
      { value: 'anual_insider_subscription_199', price: '199' },
    ];

    const selectedOption = options.find((option) => option.price == value); // Match by value

    setFormData((prev) => ({
      ...prev,
      amount: selectedOption?.price || '',
      product_id: selectedOption?.value || '',
    }));
  };
  
  return (
    <>
      <MainContainer>
        <Loader isLoad={loading} />
        <div className="main_loyout">
          <div className="dashboard">
            <div className="heading-bread">
              <h1 className="mn_hdng">Coupon code</h1>
            </div>
          </div>
          <Card className="cards">
            <Box className="cards_header">
              <Grid
                container
                spacing={2}
                display="flex"
                alignItems="center"
                justifyContent="space-between"
              >
                <Grid item>
                  <SearchBar
                    placeholder="Search by Name or Email"
                    searchTerm={searchTerm}
                    setDebouncedSearchTerm={setDebouncedSearchTerm}
                    value={searchTerm}
                    onCross={() => setSearchTerm("")}
                    onChange={(val: any) => {
                      if (isValidInput(val.target.value)) {
                        setPage(1);
                        setSearchTerm(val.target.value);
                      }
                    }}
                  />
                </Grid>
                <Grid item>
                  <div className="parent-box">
                    <Button
                      onClick={() => handleOpen()}
                      id="basic-button"
                      variant="contained"
                    >
                      Create coupon
                    </Button>
                  </div>
                </Grid>
              </Grid>
            </Box>
            <TableContainer className="table_container">
              <Box className="heading"></Box>
              <Table sx={{ minWidth: 650 }} aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TableCell align="center">COUPON CODE</TableCell>
                    <TableCell align="center">amount AMOUNNT</TableCell>
                    <TableCell align="center">EXPIRY DATE</TableCell>
                    <TableCell align="center">PRODUCT ID</TableCell>
                    <TableCell align="center">STATUS</TableCell>
                    <TableCell align="right">Actions</TableCell>
                  </TableRow>
                </TableHead>

                <TableBody>
                  {allcouponcode &&
                    allcouponcode.length > 0 &&
                    allcouponcode.map((item: any, index: number) => {
                      return (
                        <TableRow key={index}>
                          <TableCell align="center">
                            {item?.coupon_code}
                          </TableCell>
                          <TableCell align="center">{item?.amount}</TableCell>
                          <TableCell align="center">
                            {moment(item?.expiry_date).format(
                              "MMM DD, YYYY (HH:mm)"
                            )}
                          </TableCell>
                          <TableCell align="center">
                            {item?.product_id ? item?.product_id : "-"}
                          </TableCell>
                          <TableCell align="center">
                            <span
                              className={`status ${
                                item?.status === "active"
                                  ? "Active"
                                  : item?.status === "deleted"
                                    ? "Banned"
                                    : "Disabled"
                              }`}
                            >
                              {item?.status ? item?.status : "-"}
                            </span>
                          </TableCell>
                          <TableCell align="right">
                            <Box className="table_actions">
                              <Select
                                className="select_div"
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                value={""}
                                style={{ width: "92px" }}
                                inputProps={{
                                  "aria-label": "Without label",
                                }}
                                displayEmpty
                                disabled={item?.status === "deleted"}
                              >
                                <MenuItem
                                  className="action-item"
                                  style={{ display: "none" }}
                                  disabled
                                  value=""
                                >
                                  Actions{" "}
                                </MenuItem>
                                {item?.status !== "deleted" &&
                                  <MenuItem
                                  value="delete"
                                  className="action-item"
                                  onClick={() => handlechangestatus(item , "deleted")}
                                  >
                                  Delete
                                </MenuItem>
                                }
                                {/* {item?.status === "inactive" && (
                              <MenuItem
                                value="active"
                                className="action-item"
                                onClick={() => {
                                  handlechangestatus(item, "active");
                                }}
                              >
                                Active
                              </MenuItem>
                            )}
                            {item?.status === "active" && (
                              <MenuItem
                                onClick={() => {
                                  handlechangestatus(item, "inactive");
                                }}
                                value="inactive"
                                className="action-item"
                              >
                                Inactive
                              </MenuItem>
                            )} */}
                                {item?.status !== "deleted" && (
                                  <MenuItem
                                    value="edit"
                                    className="action-item"
                                    onClick={() => handleOpen(item)} // Pass the item to handleOpen
                                  >
                                    Edit
                                  </MenuItem>
                                )}
                              </Select>
                            </Box>
                          </TableCell>
                        </TableRow>
                      );
                    })}
                </TableBody>
              </Table>
            </TableContainer>
          </Card>
          <Pagination
            module={allcouponcode || []}
            page={page}
            onPageChange={onPageChange}
            totalPages={totalPages}
          />
        </div>
      </MainContainer>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            height: "100vh",
          }}
          onClick={handleClose}
        >
          <div
            style={{
              width: "500px", // Adjust the width as needed
              backgroundColor: "white",
              padding: "20px",
              borderRadius: "8px",
              outline: "none",
            }}
            onClick={(e) => e.stopPropagation()}
          >
            <p>{isEditMode ? "Edit Coupon Code" : "Create Coupon Code"}</p>
            <TextField
              name="coupon_code"
              label="Coupon code"
              value={formData.coupon_code}
              onChange={handleChange}
              fullWidth
              margin="normal"
              error={!!errors.coupon_code}
              helperText={errors.coupon_code}
              // disabled={isEditMode}
            />
            <FormControl fullWidth sx={{ marginTop: '10px' }}>
              <InputLabel id="demo-simple-select-label">Amount</InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={formData.amount}
                label="amount"
                name="amount"
                onChange={(event) => handleChangeAnnualPlan(event)}
              >
                <MenuItem value={'199'}>199</MenuItem>
                <MenuItem value={'299'}>299</MenuItem>
              </Select>
            </FormControl>
            {/* <TextField
              name="amount"
              label="Amount"
              type="number" // Ensure only numeric input
              value={formData.amount}
              onChange={handleChange}
              fullWidth
              margin="normal"
              error={!!errors.amount}
              helperText={errors.amount}
            /> */}
            <TextField
              name="expiry_date"
              label="Expiry Date"
              type="datetime-local"
              value={formData?.expiry_date ? moment(formData?.expiry_date).tz("Asia/Kolkata").format("YYYY-MM-DDTHH:mm") : ""}
              onChange={handleChange}
              fullWidth
              margin="normal"
              InputLabelProps={{
                shrink: true,
              }}
              error={!!errors.expiry_date}
              helperText={errors.expiry_date}
              inputProps={{
                min: getCurrentDateTime(), // Sets both date and time restrictions
              }}
            />
            {/* <FormControl fullWidth sx={{ marginTop: '10px' }}>
              <InputLabel id="demo-simple-select-label">Product Id</InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={formData.product_id}
                label="product_id"
                name="product_id"
                onChange={(event) => handleChangeAnnualPlan(event)}
              >
                <MenuItem value={'anual_insider_subscription_199'}>anual_insider_subscription_199</MenuItem>
                <MenuItem value={'anual_insider_subscription_299'}>anual_insider_subscription_299</MenuItem>
              </Select>
            </FormControl> */}
            {/* <TextField
              name="product_id"
              label="Product ID"
              value={formData.product_id}
              onChange={handleChange}
              fullWidth
              margin="normal"
              error={!!errors.product_id}
              helperText={errors.product_id}
            /> */}
            <div
              style={{
                marginTop: "10px",
                width: "100%",
                display: "flex",
                alignItems: "end",
                justifyContent: "end",
              }}
            >
              <Button onClick={handleCreate} variant="contained">
                {isEditMode ? "Update" : "Create"}
              </Button>
            </div>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default Couponcode;
