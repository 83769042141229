
import { END_POINTS } from "../../constants/url";
import { get, post, put } from "../../utils/AxiosMethods"

export const Getcouponcode = async ({ limit, search, page }: { limit: number; page: number; search: string }) => {
    try {
        const response = await get(`${END_POINTS.getcouponcode}?limit=${limit}&page=${page}&coupon_code=${search}`);
        return response;
    } catch (error) {
        console.log(error);
    }
}

export const Createcouponcode = async (body: any) => {
    try {
        const response = await post(END_POINTS.createCouponCode, body);
        return response;
    } catch (error) {
        return error;
    }
}
export const Editcouponcode = async (body: any) => {
    try {
        const response = await put(END_POINTS.editCouponCode, body);
        return response;
    } catch (error) {
        console.log(error);
    }
}