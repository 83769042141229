import { useEffect, useState } from "react";
import {
  Button,
  Card,
  FormControlLabel,
  Grid,
  Switch,
  TextField,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import * as Yup from "yup";

import MainContainer from "../../../layout/MainContainer";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useFormik } from "formik";
import {
  AddArticle,
  DeleteArticle,
  GetArticleById,
  UpdateArticle,
} from "../../../services/home";
import { Loader, showError, showToast } from "../../../constants";
import { Article } from "../../../types/General";
import { WarnModal } from "../../../components";
import { isValidInputWithSC } from "../../../utils/validations";
import { EditorState } from "draft-js";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { Editor } from "react-draft-wysiwyg";
// import ReactQuill from "react-quill-2";
import "react-quill/dist/quill.snow.css";

import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { Padding } from "@mui/icons-material";
// import "react-quill-2/dist/quill.snow.css";

// import '../../../../node_modules/react-draft-wysiwyg/dist/react-draft-wysiwyg.css';

const ArticleForm = () => {
  const { articleId } = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  // const [description, setDescription] = useState(EditorState.createEmpty());
  const [description, setDescription] = useState("");
  const [excerpts, setexcerpts] = useState("");
  const { categoryId } = useParams();

  const [headline, setHeadline] = useState("");
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  // const [description, setDescription] = useState<any>();
  const [image, setImage] = useState<File | string>("");
  const [imagePreview, setImagePreview] = useState("");
  const [status, setStatus] = useState("Published");
  const [otherDetails, setOtherDetails] = useState<Article | null>(null);
  const [isChecked, setIsChecked] = useState(false);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setIsChecked(event.target.checked);
  };

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      headlineText: headline || "",
      // description: description || "",
    },
    validationSchema: Yup.object({
      headlineText: Yup.string()
        .required("Headline text is required")
        .max(100, "Maximum 100 character are allowed"),
      // description: Yup.string()
      //   .required("Description is required")
      //   .max(10000, "Maximum 10000 character are allowed"),
    }),
    onSubmit: async (values) => {
      formik.setSubmitting(true);

      if (!imagePreview?.length) {
        showError("Image is required");
        return;
      }
      submitArticle(status);
    },
  });

  // const onEditorStateChange = (newState: any) => {
  //   console.log(newState, "hhhhhhhhhhh")
  //   setDescription(newState);
  // };

  const submitArticle = async (status: any) => {
    const formData = new FormData();
    // console.log(editorState)
    formData.append("image", image);
    formData.append("status", status);
    formData.append("description", description.trim());
    formData.append("excerpts", excerpts.trim());
    formData.append("title", formik.values.headlineText);
    formData.append("categoriesId", categoryId || "");
    formData.append("isPaid", isChecked.toString());
    formData.append("postType", "Article");
    setLoading(true);

    try {
      let response: any;
      let status = "";
      console.log("hello");
      if (articleId) {
        response = (await UpdateArticle(formData, articleId || "")) as {
          status: number;
        };
        status = "updated";
      } else {
        console.log(formData, "lllllllll");

        response = (await AddArticle(formData)) as { status: number };
        status = "created";
      }
      setLoading(false);

      if (response?.status === 201) {
        showToast(`Article ${status} successfully`);
        navigate(-1);
      }
    } catch (error: any) {
      setLoading(false);
      console.log(error);
    }
  };

  function createObjectURL(object: File) {
    return window.URL
      ? window.URL.createObjectURL(object)
      : window.webkitURL.createObjectURL(object);
  }

  const handleImageUpload = async (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    if (event?.target?.files?.length) {
      const reader = new FileReader();
      const file = event?.target?.files[0];
      if (file && file.type.includes("image")) {
        reader.onload = async (event) => {
          const image = new Image();
          image.src = event?.target?.result as string;
          image.onload = () => {
            if (image.width >= 1920 && image.height >= 1080) {
              let url = createObjectURL(file);
              setImagePreview(url);
              setImage(file);
            } else {
              showError(
                `Image must be 1920x1080 resolution. This image has ${image.width}x${image.height} resolution`
              );
            }
          };
        };
        reader.readAsDataURL(file);
      } else {
        showError("Only image types are allowed");
      }
    }
  };

  const getArticleDetails = async () => {
    if (!articleId) {
      return;
    }
    try {
      setLoading(true);
      const response = (await GetArticleById({ articleId })) as {
        data: Article;
        code: number;
      };
      console.log(response?.data?.description);
      if (response?.code === 200) {
        const html1 = response?.data?.description?.replace(/<p>\s*<\/p>/gi, "");
        const html2 = html1?.replace(/<br>\s*/gi, "");
        const html = html2.replace(/<p>\s*(?=<ul>)/gi, "");
        setHeadline(response?.data?.title || "");
        setDescription(response?.data?.description || "");
        setexcerpts(response?.data?.excerpts || "");
        setIsChecked(response?.data?.isPaid || "");
        setImage(response?.data?.image || "");
        setImagePreview(response?.data?.image || "");
        setOtherDetails(response?.data || null);
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  };

  const discardArticle = async () => {
    try {
      if (!articleId) {
        return;
      }
      setLoading(true);

      const response = (await DeleteArticle(articleId)) as { status: number };
      if (response?.status === 200) {
        setLoading(false);
        showToast(`Article deleted successfully`);
        navigate(-1);
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  };

  useEffect(() => {
    if (location?.pathname?.includes("edit")) {
      getArticleDetails();
    }
  }, []);
  const handleDescriptionChange = (value: any) => {
    setDescription(value.trim());
  };

  const handleexcerptsChange = (value: any) => {
    setexcerpts(value);
  };

  return (
    <>
      <Loader isLoad={loading} />
      <MainContainer>
        <form onSubmit={formik.handleSubmit}>
          <div className="main_loyout">
            <div className="dashboard article-dash">
              <h1 className="mn_hdng">
                {/* Manage Article */}
                Add Article{" "}
              </h1>
              <Button className="btn btn_primary" onClick={() => navigate(-1)}>
                Go Back
              </Button>
            </div>
            <Card className="cards article-page">
              <Grid container spacing={2}>
                <Grid item xs={12} lg={6} sm={12}>
                  {imagePreview?.length ? (
                    <div className="image-preview">
                      <div
                        onClick={() => {
                          setImage("");
                          setImagePreview("");
                        }}
                        className="close-icon"
                      >
                        <CloseIcon />
                      </div>

                      <img src={imagePreview} alt="" />
                    </div>
                  ) : (
                    <Button
                      variant="contained"
                      component="label"
                      className="custom-input"
                    >
                      Upload File
                      <input type="file" hidden onChange={handleImageUpload} />
                    </Button>
                  )}
                </Grid>
                <Grid item xs={12} lg={6} sm={12}>
                  <TextField
                    id="headlineText"
                    label="Article headline"
                    multiline
                    className="custom-textarea"
                    type={"text"}
                    name="headlineText"
                    variant="outlined"
                    fullWidth
                    inputProps={{ maxLength: 100 }}
                    placeholder={"Enter article headline..."}
                    onBlur={formik.handleBlur}
                    value={formik.values.headlineText}
                    onChange={(val) => {
                      if (
                        val.target.value === " " ||
                        val.target.value === "."
                      ) {
                      } else if (isValidInputWithSC(val.target.value)) {
                        formik.handleChange(val);
                      }
                    }}
                    helperText={
                      formik.touched.headlineText && formik.errors.headlineText
                    }
                    rows={imagePreview ? 11 : 6}
                  />
                </Grid>
                  <Grid item xs={12} lg={6} sm={12}>
                    <TextField
                      label="Article excerpts"
                      multiline
                      type="text"
                      variant="outlined"
                      fullWidth
                      placeholder="Add article excerpts.."
                      value={excerpts}
                      onChange={(e) => handleexcerptsChange(e.target.value)}
                      sx={{
                        minHeight: "150px", // Sets minimum height
                        ".MuiInputBase-root": {
                          minHeight: "150px", // Ensures text area is 300px tall
                          display: "flex",
                          alignItems: "flex-start", // Aligns text to the top
                        },
                        ".MuiInputBase-input": {
                          textAlign: "start", // Ensures text alignment to start
                        },
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} lg={6} sm={12}>
                    <FormControlLabel
                      control={
                        <Switch
                          checked={isChecked}
                          onChange={handleChange}
                          name="isPaid"
                          color="primary"
                        />
                      }
                      label="Is Paid"
                    />
                  </Grid>
                </Grid>
                <Grid item xs={12} sx={{ paddingTop: '16px' }}>
                  <div className="react-Quill">
                    {/* <h1>Description</h1> */}
                    <ReactQuill
                      value={description}
                      onChange={handleDescriptionChange}
                      modules={{
                        toolbar: [
                          [{ header: [1, 2, false] }],
                          ["bold", "italic", "underline", "strike"],
                          [{ list: "ordered" }, { list: "bullet" }],
                          ["link", "image"],
                          [{ align: [] }],
                          ["clean"],
                        ],
                        clipboard: {
                          matchVisual: false,
                        },
                      }}
                    placeholder="Enter article description.."
                    />
                  </div>
                  {/* <div>
                    <Editor
                      editorState={description}
                      toolbarClassName="toolbarClassName"
                      wrapperClassName="wrappaerClassName"
                      editorClassName="editorClassName"
                      onEditorStateChange={onEditorStateChange}
                    />
                  </div> */}
                  {/* <Editor
                    editorState={description}
                    toolbarClassName="toolbarClassName"
                    wrapperClassName="wrapperClassName"
                    editorClassName="editorClassName"
                    onEditorStateChange={onEditorStateChange}
                  /> */}
                  {/* <TextField
                    id="description"
                    label="Please enter your description"
                    multiline
                    type={"text"}
                    name="description"
                    variant="outlined"
                    fullWidth
                    // inputProps={{ maxLength: 10000 }}
                    placeholder={"Description"}
                    onBlur={formik.handleBlur}
                    value={formik.values.description}
                    onChange={(val) => {
                      if (
                        val.target.value === " " ||
                        val.target.value === "."
                      ) {
                      } else if (isValidInputWithSC(val.target.value)) {
                        formik.handleChange(val);
                      }
                    }}
                    helperText={
                      formik.touched.description && formik.errors.description
                    }
                    rows={6}
                  /> */}
                </Grid>
              <Grid container spacing={2} mt={2}>
                <Grid
                  item
                  xs={12}
                  lg={6}
                  sm={6}
                  display="flex"
                  justifyContent={{ xs: "center", lg: "flex-start" }}
                >
                  {articleId ? (
                    <ul className="footer-list">
                      <li>{otherDetails?.uuid || "N/A"}</li>
                      <li>{otherDetails?.author || "N/A"}</li>
                      <li>{otherDetails?.status || "N/A"}</li>
                    </ul>
                  ) : null}
                </Grid>
                <Grid
                  item
                  xs={12}
                  lg={6}
                  sm={6}
                  display="flex"
                  justifyContent={{ xs: "center", lg: "flex-end" }}
                  alignItems="center"
                >
                  {articleId ? (
                    <>
                      <Button
                        type="submit"
                        id="basic-button"
                        variant="contained"
                        className="update-btn"
                        onClick={() => setOpen(true)}
                        sx={{
                          display:
                            otherDetails?.status === "Draft" ||
                            otherDetails?.status === "Received"
                              ? "block"
                              : "none",
                        }}
                      >
                        Discard
                      </Button>
                      <Button
                        style={{ left: "-8px" }}
                        type="submit"
                        id="basic-button"
                        variant="contained"
                        onClick={() => setStatus("Disabled")}
                        sx={{
                          display:
                            otherDetails?.status === "Published"
                              ? "block"
                              : "none",
                        }}
                      >
                        Disable
                      </Button>
                      <Button
                        id="basic-button"
                        variant="contained"
                        className="update-btn"
                        onClick={() => {
                          setStatus("Draft");
                          submitArticle("Draft");
                        }}
                        sx={{
                          display:
                            otherDetails?.status === "Draft" ||
                            otherDetails?.status === "Received"
                              ? "block"
                              : "none",
                        }}
                      >
                        Save
                      </Button>
                      <Button
                        type="submit"
                        id="basic-button"
                        variant="contained"
                        className="update-btn"
                        onClick={() => setStatus(otherDetails?.status || "")}
                        sx={{
                          display:
                            otherDetails?.status === "Published" ||
                            otherDetails?.status === "Disabled"
                              ? "block"
                              : "none",
                        }}
                      >
                        Update
                      </Button>
                      <Button
                        type="submit"
                        id="basic-button"
                        variant="contained"
                        onClick={() => setStatus("Published")}
                        sx={{
                          display:
                            otherDetails?.status === "Draft" ||
                            otherDetails?.status === "Disabled" ||
                            otherDetails?.status === "Received"
                              ? "block"
                              : "none",
                        }}
                      >
                        Publish
                      </Button>
                    </>
                  ) : (
                    <>
                      <Button
                        id="basic-button"
                        variant="contained"
                        className="update-btn"
                        onClick={() => {
                          setStatus("Draft");
                          submitArticle("Draft");
                        }}
                      >
                        Save
                      </Button>
                      <Button
                        type="submit"
                        id="basic-button"
                        variant="contained"
                        onClick={() => setStatus("Published")}
                      >
                        Publish
                      </Button>
                    </>
                  )}
                </Grid>
              </Grid>
            </Card>
          </div>
        </form>
      </MainContainer>
      <WarnModal
        open={open}
        setOpen={setOpen}
        handleDelete={discardArticle}
        name="Article"
      />
    </>
  );
};

export default ArticleForm;
