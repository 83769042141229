import LoginPage from "./login";
import DashBoardPage from "./dashBoard";
import UsersPage from "./users";
import UserDetails from "./users/details";
import ArticlesPage from "./articles";
import AddArticlePage from "./articles/form/articleForm";
import AddVideoPage from "./videos/form/addVideo";
import ArticleDetailPage from "./articles/details";
import ReportPage from "./reported/index";
import CouponCode from "./couponcode/index";
import CommentsPage from "./Comments";
import CommentsDetail from "./Comments/details";
import PaymentPage from "./transactions";
import GSTPage from "./gst";
import CustomPage from "./customs";
import ForumPostsPage from "./forumPosts";
import ForumPostsDetails from "./forumPosts/details";

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  LoginPage,
  DashBoardPage,
  UsersPage,
  UserDetails,
  ArticlesPage,
  AddArticlePage,
  ArticleDetailPage,
  ReportPage,
  CommentsPage,
  CommentsDetail,
  PaymentPage,
  AddVideoPage,
  GSTPage,
  CustomPage,
  ForumPostsPage,
  ForumPostsDetails,
  CouponCode
};
